<template>
	<w-layout fill-height row>
		<w-flex offset-xs1 xs4 mr-2>
			<w-layout align-center column fill-height justify-center>
				<w-flex v-t="'mobileapp.name'" display-1 mb-4 shrink />
				<w-btn @click.stop="createMobileApp">{{ $t('mobileapp.actions.add') }}</w-btn>
			</w-layout>
		</w-flex>
		<w-flex xs6 ml-2>
			<w-layout align-center fill-height>
				<svg xmlns="http://www.w3.org/2000/svg" v-bind="$attrs" viewBox="0 0 790.52292 730.49185">
					<path d="m101 76h253v330h-253z" fill="#91b1c4" />
					<path d="m101 76h30v330h-30z" fill="#6b828e" />
					<path d="m101 404v49.81a41.911 41.911 0 0 0 41.86 42.19h168.76a42.354 42.354 0 0 0 42.38-42.19v-49.81z" fill="#6d7486" />
					<path d="m353.81 58.19v21.65h-253.14v-21.65a42.191 42.191 0 0 1 42.19-42.19h168.76a42.191 42.191 0 0 1 42.19 42.19z" fill="#6d7486" />
					<circle cx="227.24" cy="447.96" fill="#4a5160" r="10.55" />
					<path d="m306.667 361.667-6.067-2.937a33.935 33.935 0 0 0 -46.31 12.69l71.76 45.38z" fill="#efc597" />
					<path
						d="m195.38 122h-147.69a20.582 20.582 0 0 0 -20.69 20.65v92.61a20.671 20.671 0 0 0 20.69 20.74h70.16a1.114 1.114 0 0 1 .17.02 3.372 3.372 0 0 1 3.16 3.565c-1.75 41.16 33.07 50.552 33.07 50.552s-13.47-54.137 23.19-54.137h17.94a20.631 20.631 0 0 0 20.62-20.77v-92.58a20.523 20.523 0 0 0 -20.62-20.65z"
						fill="#5fa8ed"
					/>
					<path
						d="m481.9 343.26-25.18-82.19a21.855 21.855 0 1 0 -41.79 12.81l-4.27-13.93a21.853 21.853 0 1 0 -41.79 12.8 21.838 21.838 0 0 0 -15.06-14.66 21.548 21.548 0 0 0 -5.84-.8 21.856 21.856 0 0 0 -20.89 28.26l-32.43-105.87a21.853 21.853 0 1 0 -41.79 12.8l61.87 201.99a72.865 72.865 0 0 0 90.99 48.32l27.86-8.54a72.85 72.85 0 0 0 48.32-90.99z"
						fill="#ffd8b7"
					/>
					<path d="m131 453.81v-49.81h-30v49.81a41.911 41.911 0 0 0 41.86 42.19h30a41.911 41.911 0 0 1 -41.86-42.19z" fill="#4a4f5b" />
					<path d="m131 58.19a41.911 41.911 0 0 1 41.86-42.19h-30a41.911 41.911 0 0 0 -41.86 42.19v21.81h30z" fill="#4a4f5b" />
					<g fill="#4d88ba">
						<path d="m103.337 170.7h-45.762a6 6 0 0 1 0-12h45.762a6 6 0 1 1 0 12z" />
						<path d="m185.5 170.7h-57.2a6 6 0 0 1 0-12h57.2a6 6 0 0 1 0 12z" />
						<path d="m149.1 196.688h-91.525a6 6 0 1 1 0-12h91.525a6 6 0 0 1 0 12z" />
						<path d="m185.5 196.688h-11.441a6 6 0 1 1 0-12h11.441a6 6 0 0 1 0 12z" />
						<path d="m107.5 222.681h-49.925a6 6 0 0 1 0-12h49.925a6 6 0 0 1 0 12z" />
						<path d="m185.5 222.681h-53.042a6 6 0 0 1 0-12h53.042a6 6 0 0 1 0 12z" />
					</g>
					<path d="m128 60h-2a6 6 0 0 1 0-12h2a6 6 0 0 1 0 12z" fill="#f4f8fc" />
					<path d="m240 60h-92a6 6 0 0 1 0-12h92a6 6 0 0 1 0 12z" fill="#f4f8fc" />
					<rect x="776.82937" y="612.36811" width="94.98996" height="122.12995" transform="translate(-312.49937 74.63623) rotate(-10.37053)" fill="#e6e6e6" />
					<rect x="782.61021" y="634.86162" width="70.15431" height="4.60868" transform="translate(-306.07923 72.84904) rotate(-10.37053)" fill="#fff" />
					<rect x="784.82254" y="646.95066" width="70.15431" height="4.60868" transform="translate(-308.21928 73.44477) rotate(-10.37053)" fill="#fff" />
					<rect x="787.03487" y="659.0397" width="70.15431" height="4.60868" transform="translate(-310.35932 74.0405) rotate(-10.37053)" fill="#fff" />
					<rect x="789.2472" y="671.12874" width="70.15431" height="4.60868" transform="translate(-312.49937 74.63623) rotate(-10.37053)" fill="#fff" />
					<rect x="791.45952" y="683.21779" width="70.15431" height="4.60868" transform="translate(-314.63942 75.23197) rotate(-10.37053)" fill="#fff" />
					<rect x="793.67185" y="695.30683" width="70.15431" height="4.60868" transform="translate(-316.77946 75.8277) rotate(-10.37053)" fill="#fff" />
					<rect x="795.88418" y="707.39587" width="70.15431" height="4.60868" transform="translate(-318.91951 76.42343) rotate(-10.37053)" fill="#fff" />
					<path
						d="M851.14474,579.59059l1.21542,12.15415s-7.29249,26.73911,8.5079,26.73911,3.64624-26.73911,3.64624-26.73911l1.21542-9.72332Z"
						transform="translate(-204.73854 -84.75407)"
						fill="#a0616a"
					/>
					<path
						d="M961.54032,539.0976,955.96349,549.965s-20.63925,18.49815-7.36938,27.075,17.577-20.47743,17.577-20.47743l6.29883-7.50632Z"
						transform="translate(-204.73854 -84.75407)"
						fill="#a0616a"
					/>
					<path
						d="M870.59137,590.52932l17.0158,115.46436,8.5079,82.64818s7.29249,18.23121,31.60078,2.43083l-3.64625-47.40116-2.43083-42.5395s-2.43082-41.32409-4.86165-48.61658,1.21541-31.60077,1.21541-31.60077l9.72332-40.10867Z"
						transform="translate(-204.73854 -84.75407)"
						fill="#2f2e41"
					/>
					<path
						d="M898.5459,786.211l-9.72331,10.93873s-14.585-4.86166-18.23122,4.86165,27.95453,12.15415,27.95453,12.15415,32.81619,2.43083,32.81619,0-.8793-21.02177-5.30131-22.05732S898.5459,786.211,898.5459,786.211Z"
						transform="translate(-204.73854 -84.75407)"
						fill="#2f2e41"
					/>
					<path
						d="M916.77712,584.45225l17.0158,121.54143,8.5079,82.64818s7.29248,18.23121,31.60077,2.43083l-3.64624-47.40116-2.43083-42.5395s-2.43083-41.32409-4.86166-48.61658,1.21542-31.60077,1.21542-31.60077l-2.43083-44.97033Z"
						transform="translate(-204.73854 -84.75407)"
						fill="#2f2e41"
					/>
					<path
						d="M944.73165,786.211l-9.72332,10.93873s-14.585-4.86166-18.23121,4.86165,27.95453,12.15415,27.95453,12.15415,32.81618,2.43083,32.81618,0-.8793-21.02177-5.3013-22.05732S944.73165,786.211,944.73165,786.211Z"
						transform="translate(-204.73854 -84.75407)"
						fill="#2f2e41"
					/>
					<circle cx="675.57615" cy="282.13901" r="25.5237" fill="#a0616a" />
					<polygon points="694.415 293.685 705.354 311.917 677.399 324.071 673.753 302.193 694.415 293.685" fill="#a0616a" />
					<path
						d="M913.13087,394.84762s-25.5237-2.43083-35.247,13.36955L853.89409,426.4494a13.82858,13.82858,0,0,0-5.34159,12.82422L869.376,596.60639l30.38536-1.21541,49.832-9.72332,14.585-3.64624-7.29249-64.417s-8.5079-25.5237,1.21541-42.5395l-4.86165-59.5553L919.208,402.1401Z"
						transform="translate(-204.73854 -84.75407)"
						fill="#575a89"
					/>
					<path
						d="M856.0064,431.31005l-1.82312-3.03854s-2.43083,0-2.43083,4.86166-4.25395,109.995-4.25395,109.995l-1.21541,40.10868,23.09287,2.43082,8.5079-75.35568-3.64624-49.832Z"
						transform="translate(-204.73854 -84.75407)"
						fill="#575a89"
					/>
					<path
						d="M936.22375,413.07883l17.0158,2.43083a112.94944,112.94944,0,0,1,17.0158,23.09287c7.29248,13.36956,29.16994,38.89326,24.30828,58.33989S975.117,551.63606,975.117,551.63606l-23.09288-9.72331s18.23122-42.5395,17.0158-46.18574-15.80038-25.52371-15.80038-25.52371Z"
						transform="translate(-204.73854 -84.75407)"
						fill="#575a89"
					/>
					<path
						d="M862.69118,352.91582s-20.662-1.21541-7.29249-14.585c0,0,10.93873-4.86166,15.80039-2.43083,0,0,7.29249-4.86166,7.29249-2.43083,0,0,25.5237-7.29248,29.16994,18.23122s-2.43083,37.67784-2.43083,37.67784l-17.0158-1.21541s3.64624-20.66205-9.72331-18.23122c0,0-10.93873,3.64625-12.15415,0S862.69118,352.91582,862.69118,352.91582Z"
						transform="translate(-204.73854 -84.75407)"
						fill="#2f2e41"
					/>
					<polygon points="667.676 499.09 678.615 426.166 671.322 381.195 673.753 430.109 666.461 499.09 667.676 499.09" opacity="0.2" />
				</svg>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'MobileAppNoData',
	mixins: [SuperAdminModuleGuard],
	methods: {
		createMobileApp: function () {
			this.eventBus.emit(this.events.CREATE_NEW_MOBILE_APP)
		}
	}
}
</script>
